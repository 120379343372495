import React, {useEffect, useState} from "react";
import BodyLayout from "../components/bodyLayout";
import { Link, graphql } from "gatsby";
import Img from "gatsby-image";
import { Parallax, Background } from "react-parallax";
import { Row, Col, Tooltip } from "antd";
import {WindowDimensionsCtx} from '../components/windowDimensionsProvider';
import SEO from '../components/SEO';

export default ({ data }) => {

  const [visible, setVisible] = useState({visible1:false,visible2:false});
  const customHeight={
    'mobile': {'height': '75vw', 'width':'75vw'},
    'desktop': {'height':'65vh'},
  }
  const customHeightPitch={
    'mobile': {'height': '75vw', 'width':'75vw'},
    'desktop': {'height':'55vh'},
  }

  useEffect(() => {
    if (visible.visible1) {
      setTimeout(() => {
        setVisible({visible1:false});
      },1500);
    }
    if (visible.visible2) {
      setTimeout(() => {
        setVisible({visible2:false});
      },1500);
    }
  })

  return (
  <BodyLayout color={'black'}>
    <SEO />
    <WindowDimensionsCtx.Consumer>{device => (
    <div>

    <div className="wBorder bgblack">
    <Row>
    <Col span={24} offset={0} id="hero">
      <Row>
        <Col md={{span:12, offset:3}} xs={{span:20, offset:0}} align="left">
          <h1 style={{color:'rgba(255, 255, 255, 1)', display: 'inline-block'}}>
            AVANT TOUTE AUTRE CHOSE,<br/> LA DONNÉE DOIT ÊTRE UTILE.
          </h1>
        </Col>
      </Row>
      <Row>
        <Col md={{span:12, offset:3}} xs={{span:20, offset:0}}>
          <h3 style={{color:'rgba(239, 51, 64, 1)'}}>
            DES CONCLUSIONS SIMPLES À PARTIR DE MODÈLES ET D'ALGORITHMES COMPRÉHENSIBLES
          </h3>
        </Col>
      </Row>
      <Row style={{height:'40px'}}><Col></Col></Row>
      <Row>
      <Col md={{span:2, offset:3}} xs={{span:5, offset:0}}><Img fluid={data.logoW.childImageSharp.fluid}/></Col>
      </Row>
    </Col>
    </Row>
    </div>

    <Row>
    <Col style={{height:'20px'}}>
    </Col>
    </Row>

    <Row type="flex" align="middle" style={{margin:'-10px'}}>
      <Col md={{span:12}} xs={{span:24}} style={{padding:'10px'}}>
      <Link to='/guide-investissement-rationnel'>
      <div className="wBorder">
      <Parallax
        blur={{ min: -15, max: 15 }}
        bgImageAlt="the cat"
        strength={-200}
        bgStyle={{objectFit:'contains'}}
        >
      <Col span={24} offset={0}>
        <Row id="rowArticle" type="flex" align="middle">
          <Col md={{span:24, offset:0}} xs={{span:24, offset:0}} align="center">
            <Link to='/guide-investissement-rationnel'><h2 className="gir" style={{color:'black', backgroundColor:'rgba(255, 111, 97, 0.6)', display: 'inline-block'}}>
              Guide de l'investissement rationnel
            </h2></Link>
          </Col>
        </Row>
      </Col>
        <Background>
          <Img
            imgStyle={{
              objectFit:'scale-down',
              objectPosition:(device==='mobile') ? '0px 20px':'0px 0px'
            }}
            fixed={[
              data.fileGIRMobile.childImageSharp.fixed,
              {
                ...data.fileGIRDesktop.childImageSharp.fixed,
                media: `(min-width: 768px)`
              },
            ]}
          />
        </Background>
      </Parallax>
      </div>
      </Link>
      </Col>

      <Col md={{span:12}} xs={{span:24}} style={{padding: '10px'}}>
      <Link to='/guide-connaissance-client'>
      <div className="wBorder">
      <Parallax
        blur={{ min: -15, max: 15 }}
        bgImageAlt="the cat"
        strength={-200}
        bgStyle={{objectFit:'contains'}}
        >
      <Row>
      <Col span={24} offset={0}>
        <Row id="rowArticle" type="flex" align="middle">
          <Col md={{span:24, offset:0}} xs={{span:24, offset:0}} align="center">
          <Link to='/guide-connaissance-client'><h2 className="gcc" style={{color:'black', backgroundColor:'rgba(121, 217, 124, 0.6)', display: 'inline-block'}}>
            Guide de la connaissance client
          </h2></Link>
          </Col>
        </Row>
      </Col>
      </Row>
        <Background>
          <Img
            imgStyle={{
              objectFit:'scale-down',
              objectPosition:(device==='mobile') ? '-20px 0px':'-40px 0px'
            }}
            fixed={[
              data.fileGCCMobile.childImageSharp.fixed,
              {
                ...data.fileGCCDesktop.childImageSharp.fixed,
                media: `(min-width: 768px)`
              },
            ]}
          />
        </Background>
      </Parallax>
      </div>
      </Link>
      </Col>
      </Row>

      <Row>
      <Col style={{height:'20px'}}>
      </Col>
      </Row>

      <Row type="flex" align="middle" style={{margin: '-10px'}}>
      <Col md={{span:12}} xs={{span:24}} style={{padding:'10px'}}>
      <Link to='/sites-web'>
      <div className="wBorder">
      <Parallax
        blur={{ min: -15, max: 15 }}
        bgImageAlt="the cat"
        strength={-200}
        bgStyle={{objectFit:'contains'}}
        >
      <Col span={24} offset={0}>
        <Row id="rowArticle" type="flex" align="middle">
          <Col md={{span:24, offset:0}} xs={{span:24, offset:0}} align="center">
          <Link to='/sites-web'><h2 className="sws" style={{color:'black', backgroundColor:'rgba(116, 210, 231, 0.6)', display: 'inline-block'}}>
            Création de sites web
          </h2></Link>
          </Col>
        </Row>
      </Col>
        <Background>
          <Img
            imgStyle={{
              objectFit:'scale-down',
              objectPosition:(device==='mobile') ? '0px 50px':'0px -80px'
            }}
            fixed={[
              data.fileWebsiteMobile.childImageSharp.fixed,
              {
                ...data.fileWebsiteDesktop.childImageSharp.fixed,
                media: `(min-width: 768px)`
              },
            ]}
          />
        </Background>
      </Parallax>
      </div>
      </Link>
      </Col>

      <Row>
      <Col style={{height:'2rem'}}>
      </Col>
      </Row>

      <Col md={{span:12}} xs={{span:24}} style={{padding:'10px'}}>
      <Link to='/dashboard'>
      <div className="wBorder">
      <Parallax
        blur={{ min: -15, max: 15 }}
        bgImageAlt="the cat"
        strength={-200}
        bgStyle={{objectFit:'contains'}}
        >
      <Row>
      <Col span={24} offset={0}>
        <Row id="rowArticle" type="flex" align="middle">
          <Col md={{span:24, offset:0}} xs={{span:24, offset:0}} align="center">
            <Link to='/dashboard'><h2 className="dshb" style={{backgroundColor:'rgba(76, 64, 132, 0.6)', display: 'inline-block'}}>
              Un tableau de bord pour mon business
            </h2></Link>
          </Col>
        </Row>
      </Col>
      </Row>
        <Background>
          <Img
            imgStyle={{
              objectFit:'scale-down',
              objectPosition:(device==='mobile') ? '20px -60px':'10px -200px'
            }}
            fixed={[
              data.fileDashboardMobile.childImageSharp.fixed,
              {
                ...data.fileDashboardDesktop.childImageSharp.fixed,
                media: `(min-width: 768px)`
              },
            ]}
          />
        </Background>
      </Parallax>
      </div>
      </Link>
      </Col>
    </Row>

      <Row type="flex" align="middle" style={{marginTop: 25, marginBottom: 25}}>
        <Col span={24} align="left">
          <p>
            et plein d'autres solutions technologiques qui permettent de gagner beaucoup de temps
          </p>
        </Col>
      </Row>
    </div>
  )}
  </WindowDimensionsCtx.Consumer>
  </BodyLayout>
)}

export const query = graphql`
  {
    site {
      siteMetadata {
        title
        breakpoint
      }
    }
    logoW: file(relativePath: { eq: "img/logo/V2-Logo-Statiste-W.png" }) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    fileDashboardDesktop: file(relativePath: { eq: "img/ice_cube.jpg" }) {
      childImageSharp {
        fixed(width:800,fit:CONTAIN) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    fileDashboardMobile: file(relativePath: { eq: "img/ice_cube.jpg" }) {
      childImageSharp {
        fixed(fit:CONTAIN) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    fileGIRDesktop: file(relativePath: { eq: "img/sunset_mountain.jpg" }) {
      childImageSharp {
        fixed(height: 1000, fit:CONTAIN) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    fileGIRMobile: file(relativePath: { eq: "img/sunset_mountain.jpg" }) {
      childImageSharp {
        fixed(height:600, fit:CONTAIN) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    fileWebsiteDesktop: file(relativePath: { eq: "img/ice_mountain.jpg" }) {
      childImageSharp {
        fixed(height:1200, fit:CONTAIN) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    fileWebsiteMobile: file(relativePath: { eq: "img/ice_mountain.jpg" }) {
      childImageSharp {
        fixed(width:400, fit:CONTAIN) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    fileGCCDesktop: file(relativePath: { eq: "img/ice_tree.jpg" }) {
      childImageSharp {
        fixed(height:800, fit:CONTAIN) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    fileGCCMobile: file(relativePath: { eq: "img/ice_tree.jpg" }) {
      childImageSharp {
        fixed(height:600, fit:CONTAIN) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
  `
